// src/components/YourDocuments.tsx

import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import yourDocumentPlaceholder from "../../../assets/homepage/yourDocumentPlaceholder.svg";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  NewFloorPlanItem,
  setFloorPlan,
  setSelectedFloorPlan,
} from "../../../redux/features/graph"; // Adjust the import path as needed
import { useNavigate } from "react-router-dom";

interface Document {
  id: number;
  name: string;
  date_created: string;
  date_modified: string;
}

interface ApiResponse {
  documents: Document[];
}

interface DocCardProps {
  id: number;
  title: string;
  date: string;
  imageSrc: string;
}

function formatISODate(isoDateString: string): string {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
}

const YourDocuments: React.FC = () => {
  const [floorplanDetails, setFloorplanDetails] = useState<ApiResponse | null>(
    null,
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found");
          return;
        }

        const url = `${process.env.REACT_APP_BASEURL}/api/document/`;
        const response = await axios.get<ApiResponse>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setFloorplanDetails(response.data);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, []);

  // **DocCard Component Defined Within YourDocuments**
  const DocCard: React.FC<DocCardProps> = ({ id, title, date, imageSrc }) => {
    const handleClick = async (docId: number) => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found");
          return;
        }

        const url = `${process.env.REACT_APP_BASEURL}/api/document/${docId}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const newFloorPlanItems: NewFloorPlanItem[] =
          response.data.floorplans.map((item: any) => ({
            _id: item._id,
            name: item.name,
            label_coord: item.label_coord,
            area: item.area,
            width: item.width,
            height: item.height,
            assets: item.assets,
            color: item.color,
            walls: item.wall, // Renaming 'wall' to 'walls'
            circular_coordinates: item.circular_coordinates,
          }));

        dispatch(setFloorPlan([newFloorPlanItems]));
        dispatch(setSelectedFloorPlan(newFloorPlanItems));

        // Redirect to the Output Editor
        navigate("/outputeditor"); // Ensure this route exists in your routing configuration
      } catch (error) {
        console.error("Error fetching document details:", error);
      }
    };

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
        <Box
          width="100%"
          onClick={() => handleClick(id)}
          sx={{
            overflow: "hidden",
            "&:hover": { cursor: "pointer", boxShadow: 3 },
            borderRadius: 2,
            border: "1px solid #e0e0e0",
            p: 2,
          }}
        >
          <img
            src={imageSrc}
            height="150px"
            width="100%"
            style={{ objectFit: "cover", borderRadius: "8px" }}
            alt={title}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 1,
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                mr: "16px",
                color: "#1C4C82",
                textAlign: "left",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                mb: "3px",
                mt: "16px",
              }}
            >
              {title}
            </Typography>
            <MoreHorizIcon htmlColor="#1C4C82" fontSize="small" />
          </Box>
          <Typography
            sx={{
              mr: "10px",
              color: "#7794B4",
              textAlign: "left",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "15px",
            }}
          >
            {date}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#fff",
        pl: "7%",
        pr: "7%",
        pb: "32px",
        mb: "36px",
        mt: "16px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={4}>
          {floorplanDetails &&
            floorplanDetails.documents.map((doc) => (
              <DocCard
                key={doc.id}
                id={doc.id}
                title={doc.name}
                date={formatISODate(doc.date_modified)}
                imageSrc={yourDocumentPlaceholder} // Replace with actual image src if available
              />
            ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export default YourDocuments;
