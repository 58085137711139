import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FloorPlanItem, graphDataInterface, GraphState, NewFloorPlanItem } from "./graph.model";
import { fetchGraphData } from "../../api/api.graph";
import { PURGE } from "redux-persist";
import { Floorplan } from "../../../features/outputEditor/components/Floorplan";

// Define a type for the slice state

// Initial state
const initialState: GraphState = {
  floorPlans: [],
  selectedFloorPlan: [],
  lastFetch: "",
  loading: false,
  isError: false,
  error: null,
  index: 0,
  dimensioned: false,
  graphData: {
    corridor: false,
    dimensioned: false,
    dimensionedCirculation: false,
    minDimEnabled: false,
    removeAddCirculation: false,
    publicEnabled: false,
    normalizeConst: false,
    limit: 0,
    corridorThickness: 0,
    starting_from: 0,
    count: 0,
    rectangular: false,
    nodes: [],
    edges: []
  }
};

// Define a thunk for the API request

// Slice
export const graphSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    setDimensioned: (state, action: PayloadAction<boolean>) => {
      state.dimensioned = action.payload;
    },
    setLastFetch: (state, action: PayloadAction<string>) => {
      state.lastFetch = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.isError = true;
      state.error = action.payload;
    },
    setIndex: (state, action: PayloadAction<number>) => {
      console.log(action.payload);
      state.index = action.payload;
    },
    setFloorPlan: (
      state,
      action: PayloadAction<NewFloorPlanItem[][]>,
    ) => {
      state.floorPlans = action.payload;
    },
    setSelectedFloorPlan: (
      state,
      action: PayloadAction<NewFloorPlanItem[]>,
    ) => {
      state.selectedFloorPlan = action.payload;
    },
    deleteSelectedFloorPlan: (state) => {
      state.selectedFloorPlan = [];
    },
    setGraphData: (state, action: PayloadAction<graphDataInterface>) => {
      state.graphData = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGraphData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGraphData.fulfilled, (state, action) => {
        state.floorPlans = action.payload;
        state.isError = false;
        state.loading = false;
        state.lastFetch = new Date().toISOString();
      })
      .addCase(fetchGraphData.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.error = action.error.message || "Failed to fetch graph data";
      });
  },
});

// Export actions
export const {
  setLastFetch,
  setLoading,
  setError,
  setIndex,
  setDimensioned,
  setFloorPlan,
  setSelectedFloorPlan,
  deleteSelectedFloorPlan,
  setGraphData,
  reset,
} = graphSlice.actions;

// Export reducer
export default graphSlice.reducer;
