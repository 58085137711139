// src/components/Plans.tsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import CheckIcon from "../../assets/check-icon.svg";
import PaidPlanIcon from "../../assets/PaidPlanIcon.svg";
import FreePlanIcon from "../../assets/FreePlanIcon.svg";
import { useSelector } from "react-redux";
import Navbar from "../home/components/Navbar";
// import { seleceCurrentUser } from "../../redux/features/auth/authSlice"; // Adjust the path as necessary
// import { RootState } from "../store"; // Adjust the path as needed

interface Plan {
  id: number;
  name: string;
  description: string;
  priceUSD: string; // Price in USD (e.g., "$3/mth")
}

interface User {
  name: string;
  email: string;
  contact: string;
  // Add other relevant fields as needed
}

const Plans: React.FC = () => {
  const navigate = useNavigate();
  //   const user = useSelector<RootState, User | undefined>(seleceCurrentUser);
  const [usdToInrRate, setUsdToInrRate] = useState<number | null>(null);
  const [fetchError, setFetchError] = useState<string | null>(null); // To handle fetch errors

  const plans: Plan[] = [
    {
      id: 1,
      name: "student",
      description: "First 15 days Free",
      priceUSD: "$3/mth",
    },
    {
      id: 2,
      name: "pro",
      description: "First 15 days Free",
      priceUSD: "$5/mth",
    },
    // Add more plans as needed
  ];

  const createOrder = async (planId: string) => {
    // if (!user) {
    //   alert("You must be logged in to create a subscription.");
    //   navigate("/login"); // Redirect to login if user data is not found
    //   return;
    // }

    const token = localStorage.getItem("access_token"); // Retrieve the token

    if (!token) {
      alert("You must be logged in to create a subscription.");
      navigate("/login"); // Redirect to login if token is not found
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/users/pg/razorpay/createSubscription`,
        {
          plan: planId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the bearer token in headers
            "Content-Type": "application/json",
          },
        },
      );

      const { id, subscription_id } = response.data.data; // Extract 'id' as 'razorpaySubscriptionId'

      if (!subscription_id) {
        throw new Error("Subscription ID not returned from backend.");
      }

      // Navigate to Payment page with order details
      navigate("/payment", {
        state: {
          order: { id, subscription_id },
        },
      });
    } catch (error: any) {
      console.error("Error creating subscription:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(`Failed to create subscription: ${error.response.data.message}`);
      } else {
        alert("Failed to create subscription. Please try again.");
      }
    }
  };

  // Currency Converter: Fetch USD to INR rate
  useEffect(() => {
    const fetchRate = async () => {
      const primaryURL =
        "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json";
      const fallbackURL =
        "https://latest.currency-api.pages.dev/v1/currencies/usd.json";
      try {
        // Attempt to fetch from the primary URL
        const response = await fetch(primaryURL);
        if (!response.ok)
          throw new Error(
            `Primary fetch failed with status ${response.status}`,
          );
        const data = await response.json();
        console.log("Primary data fetched:", data); // Debugging
        const rate = data.usd?.inr; // Corrected data access
        if (rate) {
          setUsdToInrRate(rate);
          return;
        }
        throw new Error("Rate not found in primary data");
      } catch (error) {
        console.error("Primary fetch failed, trying fallback:", error);
        try {
          // Attempt to fetch from the fallback URL
          const response = await fetch(fallbackURL);
          if (!response.ok)
            throw new Error(
              `Fallback fetch failed with status ${response.status}`,
            );
          const data = await response.json();
          console.log("Fallback data fetched:", data); // Debugging
          const rate = data.usd?.inr; // Corrected data access
          if (rate) {
            setUsdToInrRate(rate);
            return;
          }
          throw new Error("Rate not found in fallback data");
        } catch (fallbackError) {
          console.error("Fallback fetch failed:", fallbackError);
          setFetchError("Unable to fetch currency rates at this time.");
          // Optionally, set a default rate or handle the error as needed
        }
      }
    };
    fetchRate();
  }, []);

  // PricingCard Component
  const PricingCard: React.FC<{
    type: string;
    heading: string;
    priceUSD: string;
    features: Array<string>;
    usdToInrRate?: number;
    onSubscribe: () => void;
  }> = ({ type, heading, priceUSD, features, usdToInrRate, onSubscribe }) => {
    // Helper function to format INR
    const formatINR = (num: number): string => {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(num);
    };

    // Helper function to convert USD price to INR
    const convertPrice = (price: string, rate: number): string => {
      const match = price.match(/\$([\d,.]+)/);
      if (match && match[1]) {
        const amount = parseFloat(match[1].replace(/,/g, ""));
        const converted = amount * rate;
        return ` (${formatINR(converted)})`;
      }
      return "";
    };

    return (
      <div
        className="pricing-outer-div"
        style={{
          backgroundColor: type === "Free Plan" ? "#F2F7FC" : "#FFF",
          borderRadius: "8px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          position: "relative",
          padding: "20px",
        }}
      >
        {type === "student" && (
          <div
            className="pricing-top-right-corner-div"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "#FFD700",
              padding: "5px 10px",
              borderRadius: "4px",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "12px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              Most Popular
            </p>
          </div>
        )}
        <div
          className="pricing-header-div"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          <img
            src={type === "student" ? FreePlanIcon : PaidPlanIcon}
            alt={type === "Basic Plan" ? "free-plan-icon" : "paid-plan-icon"}
            style={{ width: "50px", height: "50px", marginBottom: "10px" }}
          />
          <p
            className="pricing-header-heading"
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          > 
            {heading}
          </p>
          <p
            className="pricing-header-price"
            style={{
              fontSize: "20px",
              color: "#3399cc",
              margin: "5px 0",
            }}
          >
            {priceUSD}
            {usdToInrRate && convertPrice(priceUSD, usdToInrRate)}
          </p>
          <p
            className="pricing-header-supporting-text"
            style={{
              visibility: type === "Free Plan" ? "visible" : "hidden",
              color: "#555",
            }}
          >
            {type == "Free Plan" ? "Free For 15 days" : "Billed monthly"}
          </p>
        </div>
        <Box
          className="pricing-content-div"
          sx={{ padding: "0 10px", minHeight: "150px" }}
        >
          <p
            className="pricing-content-heading"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "left",
            }}
          >
            {type === "Basic Plan" || type === "Premium Plan"
              ? "Features you'll love:"
              : ""}
          </p>
          {features.map((feature, index) => (
            <div
              className="pricing-feature-line"
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
                textAlign: "left",
              }}
            >
              <img
                src={CheckIcon}
                alt="check-icon"
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
              />
              <p
                className="pricing-feature-text"
                style={{ margin: 0, fontSize: "14px" }}
              >
                {feature}
              </p>
            </div>
          ))}
        </Box>
        <Box
          className="pricing-footer-div"
          sx={{
            padding: "20px",
            textAlign: "center",
          }}
        >
          <button
            onClick={onSubscribe}
            className="pricing-footer-button"
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              backgroundColor: "#3399cc",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
            }}
          >
            Subscribe
          </button>
        </Box>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <section id="pricing">
        <Box sx={{ flexGrow: 1, padding: "24px" }}>
          <p
            className="pricing-main-heading"
            style={{
              textAlign: "center",
              fontSize: "32px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Plan and Pricing
          </p>
          {/* Display an error message if fetch fails */}
          {fetchError && (
            <p style={{ color: "red", textAlign: "center" }}>{fetchError}</p>
          )}
          {/* Grid layout for Pricing Cards */}
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            {plans.map((plan) => (
              <Grid item xs={12} sm={4} key={plan.id}>
                <PricingCard
                  type={plan.name} // Adjust types as needed
                  heading={plan.name}
                  priceUSD={plan.priceUSD}
                  features={[
                    plan.description,
                    "Unlimited Floorplans",
                    "Dimensionless floorplans for any adjacency",
                    "Rectangular floorplans",
                    "Dimensioned floorplans",
                    "Editing a floorplan by wall movements",
                    "Exporting floorplans as JSON and DXF",
                  ]}
                  usdToInrRate={usdToInrRate || undefined}
                  onSubscribe={() => createOrder(plan.name)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </section>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
  },
  planCard: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "20px",
    margin: "10px",
    width: "300px",
    textAlign: "center",
  },
  button: {
    padding: "10px 20px",
    marginTop: "10px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#3399cc",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
  },
};

export default Plans;
