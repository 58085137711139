// src/components/Payment.tsx

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

interface LocationState {
  order: {
    id: string;
    subscription_id: string; // Correctly passing Razorpay subscription ID
  };
}

declare global {
  interface Window {
    Razorpay: any;
  }
}

interface RazorpayOptions {
  key: string;
  subscription_id?: string;
  name?: string;
  description?: string;
  image?: string;
  handler?: (response: any) => void;
  modal?: {
    ondismiss?: () => void;
  };
  prefill?: {
    name?: string;
    email?: string;
    contact?: string;
  };
  notes?: { [key: string]: any };
  theme?: { color?: string };
}

const Payment: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { order } = location.state as LocationState;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Function to load Razorpay script
    const loadRazorpayScript = (src: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (!existingScript) {
          const script = document.createElement("script");
          script.src = src;
          script.async = true;
          script.onload = () => resolve();
          script.onerror = () =>
            reject(new Error("Razorpay SDK failed to load."));
          document.body.appendChild(script);
        } else {
          resolve();
        }
      });
    };

    // Function to initialize Razorpay Checkout
    const initializeRazorpay = async () => {
      try {
        await loadRazorpayScript(
          "https://checkout.razorpay.com/v1/checkout.js",
        );

        if (!window.Razorpay) {
          throw new Error("Razorpay SDK not available.");
        }

        const options: RazorpayOptions = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID!, // Razorpay Key ID
          subscription_id: order.id, // Correct subscription ID
          name: "Gplan",
          description: "Subscription Payment",
          // image: logo, // Optional: Your company logo
          handler: handlePaymentSuccess,
          modal: {
            ondismiss: handlePaymentDismiss, // Handle payment dismissal
          },
          prefill: {
            name: `${localStorage.getItem("name")}`,
            email: `${localStorage.getItem("email")}`,
            contact: "999999999",
          },
          notes: {
            note_key_1: "Tea. Earl Grey. Hot",
            note_key_2: "Make it so.",
          },
          theme: {
            color: "#F2F2F2",
          },
        };
        console.log(options);
        const rzp = new window.Razorpay(options);

        // Handle payment failures via event listener
        rzp.on("payment.failed", handlePaymentFailure);

        rzp.open();
      } catch (err: any) {
        console.error("Error initializing Razorpay:", err);
        setError(err.message || "Failed to load Razorpay SDK.");
        setLoading(false);
      }
    };

    // Handler for successful payment
    const handlePaymentSuccess = async (response: any) => {
      console.log("Payment Success Handler Response:", response);
      setLoading(true);
      try {
        const {
          razorpay_payment_id,
          razorpay_subscription_id,
          razorpay_signature,
        } = response;

        // Extract your internal subscription_id from order
        const { subscription_id, id } = order; // Assuming 'order.subscription_id' is your internal ID

        // Prepare the request payload with additional fields
        const payload = {
          razorpay_payment_id,
          razorpay_subscription_id,
          razorpay_signature,
          subscription_id, // Your internal subscription ID
          status: "success", // Static status field
        };

        console.log("Payload Sent to Backend:", payload); // For debugging

        const result = await axios.post(
          `${process.env.REACT_APP_BASEURL}/users/pg/razorpay/subscriptionCallback`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
            },
          },
        );
        alert("Payment Successful!");
        navigate("/home"); // Redirect to dashboard or success page
      } catch (error: any) {
        console.error("Payment verification failed:", error);
        alert("Payment verification failed. Please contact support.");
        navigate("/plans"); // Redirect to plans or appropriate page
      } finally {
        setLoading(false);
      }
    };

    // Handler for payment dismissal (user closes the modal)
    const handlePaymentDismiss = () => {
      setLoading(false);
      alert("Payment was cancelled.");
      navigate("/plans"); // Redirect to plans or appropriate page
    };

    // Handler for payment failures via event listener
    const handlePaymentFailure = async (response: any) => {
      try {
        console.log("Payment Failure Handler Response:", response);
        const { error } = response;
        alert(`Payment Failed: ${error.description}`);
        const { subscription_id, id } = order; // Assuming 'order.subscription_id' is your internal ID
        // Prepare the request payload with additional fields
        const payload = {
          subscription_id, // Your internal subscription ID
          status: "failed", // Static status field
        };

        const result = await axios.post(
          `${process.env.REACT_APP_BASEURL}/users/pg/razorpay/subscriptionCallback`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
            },
          },
        );
        navigate("/plans"); // Redirect to plans or appropriate page
      } catch (err: any) {
        console.error("Error initializing Razorpay:", err);
        setError(err.message || "Failed to load Razorpay SDK.");
        setLoading(false);
        navigate("/plans");
      }
    };

    initializeRazorpay();
  }, [order.subscription_id, navigate]);

  return (
    <div style={styles.container}>
      <h2>Complete Your Payment</h2>
      {loading && <p>Loading...</p>}
      {error && <p style={styles.error}>{error}</p>}
      {!loading && !error && <p>Redirecting to payment gateway...</p>}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
  },
  error: {
    color: "red",
  },
};

export default Payment;
