//@ts-nocheck
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Label, Room, Wall } from "../components/Floorplan";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  addLabels,
  addRooms,
  addWalls,
  resetStates,
  setCleanUpDone,
} from "../../../redux/features/appState";
// import { currentFloorPlan } from "../../../constants";
import { getRandomColor } from "../utils/helpers";

export const useRoomCleanUp = () => {
  const [processedRooms, setProcessedRooms] = useState<Room[]>([]);
  const [processedWalls, setProcessedWalls] = useState<Wall[]>([]);
  const [processedLabels, setProcessedLabels] = useState<Label[]>([]);
  const index = useSelector((state: RootState) => state.graph.index);
  const floorPlans = useSelector((state: RootState) => state.graph.floorPlans);
  const selectedFloorPlan = useSelector(
    (state: RootState) => state?.graph?.selectedFloorPlan,
  );
  const doRoomCleanUp = useSelector(
    (state: RootState) => state.outputEditorButtonState.doRoomCleanUp,
  );

  const dispatch = useDispatch<AppDispatch>();

  const scaleCoordinate = (
    coord: number,
    isX: boolean,
    width: number,
    height: number,
  ) => {
    const offset = isX
      ? (visualViewport?.width || 0) * 0.3
      : (visualViewport?.height || 0) * 0.3;
    console.log("height", height);
    return coord * ((visualViewport?.height * 0.5) / height) + offset;
  };

  const createWallKey = (x1: number, y1: number, x2: number, y2: number) => {
    return x1 < x2 || (x1 === x2 && y1 < y2)
      ? `${x1},${y1}-${x2},${y2}`
      : `${x2},${y2}-${x1},${y1}`;
  };

  useEffect(() => {
    // console.log("doRoomCleanUp", doRoomCleanUp, index);
    if (!doRoomCleanUp) return;

    dispatch(resetStates());
    // console.log("floorplans in cleanup", floorPlans);
    const processFloorPlan = () => {
      const rooms: Room[] = [];
      const wallMap = new Map<string, Wall & { roomIds: string[] }>();
      const labels: Label[] = [];
      // console.log(
      //   "new floorplansss",
      //   index,
      //   floorPlans[index],
      //   floorPlans?.length,
      // );
      let minX = 1e9;
      let maxX = -1e9;
      let minY = 1e9;
      let maxY = -1e9;
      
      floorPlans[index].forEach((apiRoom) => {
      // selectedFloorPlan.forEach((apiRoom) => {
        const room: Room = {
          id: apiRoom._id,
          name: apiRoom.name,
          color: getRandomColor(),
          walls: [],
        };
        // console.log("apiRoom", apiRoom);

        apiRoom?.walls?.forEach((apiWall: any) => {
          minX = Math.min(apiWall.x1, apiWall.x2, minX);
          minY = Math.min(apiWall.y1, apiWall.y2, minY);
          maxX = Math.max(apiWall.x1, apiWall.x2, maxX);
          maxY = Math.max(apiWall.y1, apiWall.y2, maxY);
        });
      });
      const width = Math.abs(maxX - minX);
      const height = Math.abs(maxY - minY);
      if (selectedFloorPlan.length === 0) return;
      selectedFloorPlan.forEach((apiRoom) => {
        const room: Room = {
          id: apiRoom._id,
          name: apiRoom.name,
          color: getRandomColor(),
          walls: [],
        };
        // console.log("apiRoom", apiRoom);
        apiRoom?.walls?.forEach((apiWall: any) => {
          const x1 = scaleCoordinate(apiWall.x1, true, width, height);
          const y1 = scaleCoordinate(apiWall.y1, false, width, height);
          const x2 = scaleCoordinate(apiWall.x2, true, width, height);
          const y2 = scaleCoordinate(apiWall.y2, false, width, height);

          const wallKey = createWallKey(x1, y1, x2, y2);

          if (!wallMap.has(wallKey)) {
            const wall: Wall & { roomIds: string[] } = {
              id: apiWall._id,
              x1,
              y1,
              x2,
              y2,
              roomId: room.id,
              roomIds: [room.id],
            };
            wallMap.set(wallKey, wall);
          } else {
            const existingWall = wallMap.get(wallKey)!;
            if (!existingWall.roomIds.includes(room.id)) {
              existingWall.roomIds.push(room.id);
            }
          }
        });

        rooms.push(room);
        // console.log(rooms);

        // Calculate center of a rectangle of the room for label placement
        const xPos = new Map<number, number[]>();
        apiRoom?.walls?.forEach((wall) => {
          if (xPos.has(wall.x1)) {
            let y = xPos.get(wall.x1);
            y?.push(wall.y1);
            xPos.set(wall.x1, y);
          } else {
            xPos.set(wall.x1, [wall.y1]);
          }
          if (xPos.has(wall.x2)) {
            let y = xPos.get(wall.x2);
            y?.push(wall.y2);
            xPos.set(wall.x2, y);
          } else {
            xPos.set(wall.x2, [wall.y2]);
          }
        });
        let x1: number,
          x2: number,
          y1: number = Number.MAX_VALUE,
          y2: number = Number.MIN_VALUE;
        x1 = xPos.keys().next().value;
        console.log(xPos.keys().next().value);
        xPos.get(x1).forEach((y) => {
          y1 = Math.min(y1, y);
          y2 = Math.max(y2, y);
        });
        xPos.delete(x1);
        x2 = xPos.keys().next().value;
        xPos.get(x2).forEach((y) => {
          y1 = Math.min(y1, y);
          y2 = Math.max(y2, y);
        });
        x1 = scaleCoordinate(x1, true, width, height);
        x2 = scaleCoordinate(x2, true, width, height);
        y1 = scaleCoordinate(y1, false, width, height);
        y2 = scaleCoordinate(y2, false, width, height);

        labels.push({
          x: (x1 + x2) / 2,
          y: (y1 + y2) / 2,
          text: room.name,
          roomId: room?.id,
        });
      });

      // Process walls to split larger walls and remove redundant pieces
      // Process walls to split larger walls and remove redundant pieces
      const finalWalls: Wall[] = [];
      const processedWallKeys = new Map<string, Wall>();

      wallMap.forEach((wall) => {
        if (wall.x1 === wall.x2 && wall.y1 === wall.y2) {
          // Skip points
          return;
        }

        const isHorizontal = wall.y1 === wall.y2;
        const wallStart = isHorizontal ? wall.x1 : wall.y1;
        const wallEnd = isHorizontal ? wall.x2 : wall.y2;

        // Find all intersection points
        const intersections = new Set<number>([wallStart, wallEnd]);
        wallMap.forEach((otherWall) => {
          if (
            isHorizontal &&
            otherWall.y1 === wall.y1 &&
            otherWall.y2 === wall.y2
          ) {
            if (
              Math.abs(otherWall.x1 - wall.x1) +
                Math.abs(otherWall.x1 - wall.x2) ===
              Math.abs(wall.x1 - wall.x2)
            ) {
              intersections.add(otherWall.x1);
            }
            if (
              Math.abs(otherWall.x2 - wall.x1) +
                Math.abs(otherWall.x2 - wall.x2) ===
              Math.abs(wall.x1 - wall.x2)
            ) {
              intersections.add(otherWall.x2);
            }
          } else if (
            !isHorizontal &&
            otherWall.x1 === wall.x1 &&
            otherWall.x2 === wall.x2
          ) {
            if (
              Math.abs(otherWall.y1 - wall.y1) +
                Math.abs(otherWall.y1 - wall.y2) ===
              Math.abs(wall.y1 - wall.y2)
            ) {
              intersections.add(otherWall.y1);
            }
            if (
              Math.abs(otherWall.y2 - wall.y1) +
                Math.abs(otherWall.y2 - wall.y2) ===
              Math.abs(wall.y1 - wall.y2)
            ) {
              intersections.add(otherWall.y2);
            }
          }
        });

        // Sort intersection points
        const sortedIntersections = Array.from(intersections).sort(
          (a, b) => a - b,
        );

        // Create wall segments
        for (let i = 0; i < sortedIntersections.length - 1; i++) {
          const start = sortedIntersections[i];
          const end = sortedIntersections[i + 1];
          if (start === end) continue; // Skip zero-length segments

          const x1: number = isHorizontal ? start : wall.x1;
          const y1: number = isHorizontal ? wall.y1 : start;
          const x2: number = isHorizontal ? end : wall.x2;
          const y2: number = isHorizontal ? wall.y2 : end;
          let roomIds = new Set<number>();

          // -----------------------------------------------------------------------------------------
          // --------------------------------THIS NEEDS OPTIMIZARION----------------------------------
          // -----------------------------------------------------------------------------------------
          wallMap.forEach((w) => {
            const isWallHorizontal: boolean = w.y1 === w.y2;
            if (isHorizontal === isWallHorizontal) {
              if (isWallHorizontal && y1 === w.y1) {
                const x1Inside: boolean =
                  Math.abs(w.x1 - x1) + Math.abs(w.x2 - x1) ===
                  Math.abs(w.x1 - w.x2);
                const x2Inside: boolean =
                  Math.abs(w.x1 - x2) + Math.abs(w.x2 - x2) ===
                  Math.abs(w.x1 - w.x2);
                if (x1Inside && x2Inside) {
                  w.roomIds.forEach((rid) => {
                    roomIds.add(rid);
                  });
                }
              } else if (!isWallHorizontal && x1 === w.x1) {
                const y1Inside: boolean =
                  Math.abs(w.y1 - y1) + Math.abs(w.y2 - y1) ===
                  Math.abs(w.y1 - w.y2);
                const y2Inside: boolean =
                  Math.abs(w.y1 - y2) + Math.abs(w.y2 - y2) ===
                  Math.abs(w.y1 - w.y2);
                if (y1Inside && y2Inside) {
                  w.roomIds.forEach((rid) => {
                    roomIds.add(rid);
                  });
                }
              }
            }
          });
          // -----------------------------------------------------------------------------------------
          // -----------------------------------------------------------------------------------------

          const newWall: Wall = {
            id: uuidv4(),
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2,
            roomIds: [...roomIds],
          };

          const wallKey = createWallKey(
            newWall.x1,
            newWall.y1,
            newWall.x2,
            newWall.y2,
          );
          if (!processedWallKeys.has(wallKey)) {
            processedWallKeys.set(wallKey, newWall);
          } else {
            const w = processedWallKeys.get(wallKey);
            wall?.roomIds.forEach((rid) => {
              if (!w?.roomIds?.includes(rid)) {
                w?.roomIds?.push(rid);
              }
            });
            // w?.roomIds?.push(...wall?.roomIds);
            processedWallKeys.set(wallKey, w);
          }
        }
      });

      processedWallKeys.forEach((val, key) => {
        finalWalls.push(val);
      });

      // Assign walls to rooms
      rooms.forEach((room) => {
        room.walls = finalWalls.filter(
          (wall) => wall?.roomIds?.includes(room.id),
        );
      });
      // console.log(finalWalls);
      //console.log(rooms);

      setProcessedRooms(rooms);
      setProcessedWalls(finalWalls);
      setProcessedLabels(labels);
    };

    processFloorPlan();
  }, [doRoomCleanUp, selectedFloorPlan, index]);

  useEffect(() => {
    if (processedWalls.length > 0) {
      // console.log("setting processed walls", processedWalls);
      processedWalls.forEach((wall) => {
        dispatch(addWalls(wall));
      });
    }
  }, [processedWalls, dispatch]);

  useEffect(() => {
    if (processedLabels.length > 0) {
      processedLabels.forEach((label) => {
        dispatch(addLabels(label));
      });
    }
  }, [processedLabels, dispatch]);

  useEffect(() => {
    if (processedRooms.length > 0) {
      processedRooms.forEach((room) => {
        dispatch(addRooms(room));
      });
      dispatch(setCleanUpDone(true));
    }
  }, [processedRooms, dispatch]);
};
